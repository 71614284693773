/* src/Marquee.css */
.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .marquee {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 50s linear infinite;
  }
  
  .marquee:hover {
    animation-play-state: paused;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    90% {
      transform: translateX(-100%);
    }
  }
  
  .marquee span {
    display: inline-block;
    padding-right: 100%;
  }
  