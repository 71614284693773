.Navbar {
  background-color: var(--starwhite);
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden !important;
  padding: 1rem 0rem;
}

.rightSide {
  width: 50%;
  margin: 5rem 5rem;
  padding: 1.5rem 1rem;
  justify-content: center;
  align-items: center;

  z-index: 3;
}
.rightSide img {
  width: 100%;
}
.rightSide h1 {
  text-align: center;
  color: var(--starblack);
  text-decoration: none;
}
.rightSide p {
  text-align: center;
  color: var(--stargreen);
}

.rightSide img:hover {
  cursor: pointer;
}
.leftSide p {
  text-align: center;
  color: var(--starblack);
  text-decoration: none;
  padding: 0rem 5rem;
}
.leftSide a {
  text-align: center;
  color: var(--starblack);
  text-decoration: none;
}
.leftSide a:hover {
  color: var(--stargreen);
}

@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
.leftSide {
  width: 90%;
  padding: 0rem 0rem;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.leftSide img {
  width: 100%;
}

@media (max-width: 1120px) {
  .Navbar {
    display: none;
  }
}

@media (max-width: 700px) {
  .Navbar {
    display: none;
  }
  .leftSide {
    width: 100%;
    display: flex;
  }
  .rightSide {
    display: none;
  }

  .leftSide button {
    margin: 2rem;
    display: flex;
    width: fit-content;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .imageContainer {
    margin-top: 10px;
    margin-left: 2px;
    padding-top: 0;
    width: 80%;
  }

  .leftSide {
    position: absolute;
    background-color: #f0f3f5;
    left: 0px;
    top: 50px;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    z-index: 1;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .leftSide li {
    font-size: 0.85rem;
    display: block;
    padding-left: 10px;
  }

  .leftSide a :hover {
    color: #fff !important;
    font-weight: bold;
  }
}
