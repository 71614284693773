.mainContainer {
  margin: 0rem;
  width: 100%;
  justify-content: center !important;
  align-items: center;
  overflow: hidden !important;
}
/* Green Backround */
.slider1 {
  padding: 0rem;
  background-color: var(--starbackgroundgreen);
}

.slider1 img {
  padding: 0;
  width: 100%;
}

.slider1 button {
  position: relative;
  margin: -3rem 1rem !important;
  float: right;
  display: block; 
  background-color: var(--stargreen);
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  width: 25%;

  
}
/* Green Maroon */
.slider2 {
  padding: 0rem;
  background-color: var(--starwhite);
}

.slider2 img {
  padding: 0;
  width: 100%;
}

.slider2 button {
  position: relative;
  margin: -3rem 1rem !important;
  float: right;
  display: block; 
  background-color: var(--stargreen);
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  width: 25%;

  
}

/* Form Submitted */
.submittedForm {
  text-align: center;
  margin-top: -8rem;
  color: var(--starwhite);
}

@media (max-width: 1120px) {
}

@media (max-width: 700px) {
  .slider1 button {
    padding: 0.5rem;
    margin: -2rem 0.2rem !important;
    width: 25%; 
  }
  .slider2{
    padding: 0rem;
  }
  .slider2 button {
    padding: 0.5rem;
    margin: -2rem 0.2rem !important;
    width: 25%; 
  }
}
