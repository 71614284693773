/* NavBar.css */
.navbar {
  display: flex;
  margin: 0 auto;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: -5px;
  z-index: 2;
  height: 60px;
  width: 100%;
  background-color: var(--starwhite);
  padding: 0rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
  color: var(--starblack);
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin-top: 2.5rem;
  color: var(--stargreen);
}
.logoDisplayMinimized {
  display: none;
}
.nav-title {
  width: 25%;
  display: inline-flex;
  margin: 0 4rem;
  text-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--stargreen);
  padding: 1rem;
}

.nav-title img {
  width: 40%;
}
.nav-links {
  width: 70%;
  font-size: small;
  list-style: none;
  display: flex;
  white-space: nowrap;
}

.nav-links li {
  margin-right: 1rem;
  padding-left: 1rem;
  position: relative;
  list-style: none;
  line-height: 2rem;
}

.nav-links a {
  text-decoration: none;
  color: var(--starblack);
}
.nav-links a:hover {
  text-decoration: none;
  color: var(--stargreen);
  font-weight: bold;
}

.sub-links {
  width: max-content;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: var(--starblack);
  z-index: 1;
  padding: 0;
}

.sub-links li {
  width: 88%;
  border-bottom: 0.045rem solid #fff;
}
.sub-links a {
  padding: 0.5rem;
  color: #fff;
}
.sub-links a:hover {
  color: var(--staryellow);
}
.sub-links li:hover {
  padding: 0%;
  width: 100%;
}

.nav-links li:hover .sub-links {
  display: block;
}

.nonlinks {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar input {
  margin-left: 0;
  width: 75%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
}
.navbar input:focus {
  background-color: var(--secondaryColor);
}
.navbar p {
  padding: 0rem 0.5rem;
  color: #fff;
  cursor: pointer;
}

.TitleLink {
  display: flex;
  margin-top: 0.2rem;
}

.marqueeWrapper {
  width: 100%; 
}

@media screen and (max-width: 1120px) {
}

@media screen and (max-width: 700px) {
  .navbar {
    height: 100px;
  }
  .menu-icon {
    display: block;
    padding-right: 0.5rem;
  }
  .logoDisplayMinimized {
    color: var(--starblack);
    justify-content: center;
    align-items: center;
    text-wrap: nowrap;
    display: flex !important;
    flex-direction: row !important;
  }
  .logoDisplayMinimized img {
    width: 100%;
    padding-left: 0.5rem;
  }

  .titleHidden {
    display: none;
  }
  .nav-title {
    display: none;
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    display: none;
    background-color: var(--starblack);
  }
  .nav-links li {
    margin: 0;
    padding: 0.5rem;
    line-height: 1rem;
    border-bottom: 0.02rem solid var(--starwhite);
    text-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .nav-links a {
    color: var(--starwhite);
  }
  .nav-links li:hover {
  }

  .sub-links {
    width: max-content;
    position: absolute;
    left: 0rem;
    top: 40px;
    z-index: 1;
  }

  .nav-links.active {
    display: flex;
  }
  .sub-links li {
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
  }
  .sub-links li:hover {
    padding: 0.5rem;
  }
  .socialLinks {
    margin-top: -10rem;
    width: 70%;
    float: right !important;
    margin-right: 10px;
  }
}
