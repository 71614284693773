.mainContainer {
  margin: 0;
  width: 100%;
  justify-content: center !important;
  align-items: center;
  overflow: hidden !important;
}

/* Slider1 */
.slider1 {
  background-color: var(--starbackgroundgreen);
}
.slider1 h1 {
  font-size: 4rem;
  text-align: center;
  color: var(--stargreen);
}
.slider1 h3 {
  font-weight: bold;
  color: var(--starblue);
}
.slider1 img {
  padding: 2rem;
  width: 60%;
}
.buttonContainer {
  width: 100%;
  margin: 2rem;
}
.slider1 button {
  margin: 1rem;
  display: block;
  background-color: var(--starblack);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}
.slider1 button:hover {
  background-color: var(--stargreen);
}

/* Slider2 */
.slider2 {
  background-color: var(--starbackgroundblue);
}
.slider2 h3 {
  font-weight: bold;
  color: var(--starblue);
}

.slider2 p {
  text-align: left;
  padding: 0 3rem;
  color: var(--starblack);
}

.slider2 img {
  padding: 2rem;
  width: 60%;
}
.slider2 button {
  margin: 1rem;
  display: block;
  background-color: var(--starblue);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}
.slider2 button:hover {
  background-color: var(--stargreen);
}

/* Slider3 */
.slider3 {
  background-color: var(--starmaroon);
  color: var(--starwhite) !important;
}
.slider3 img {
  padding: 2rem;
  width: 25%;
}

/* Slider4 */
.slider4 {
  padding: 2rem 5rem;
  background-color: var(--starbackgroundgreen);
}

/* Slider5 */
.slider5 {
  background-color: var(--starbackgroundgreen);
}

/* Slider6 */
.slider6 {
  background-color: var(--starbackgroundgreen);
  padding: 0rem 5rem !important;
}
.slider6 h1 {
  font-size: 4rem;
  text-align: center;
  color: var(--stargreen);
}
.slider6 img {
  width: 120%;
  border-radius: 1rem;
}
.slider6 button {
  margin: 1rem;
  display: block;
  background-color: var(--starblack);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}
.slider6 button:hover {
  background-color: var(--stargreen);
}
.slider6 img:hover {
  transform: scale(1.2);
}

/* Slider7 */
.slider7 {
  margin: 0 auto;
  background-color: var(--starmaroon);
  justify-content: center;
  align-items: center;
  text-align: center;
}
.slider7 h3 {
  font-size: 2rem;
  text-align: left;
  color: var(--starwhite);
}
.slider7 h4 {
  font-size: 1.5rem;
  color: var(--starwhite);
}
.slider7 li {
  justify-content: center !important;
  align-items: center !important;
  padding-left: 5rem;
  text-decoration: none;
  color: #fff;
  text-align: left;
  line-height: 2rem;
  list-style-type: none;
}

.slider7 a {
  color: #fff;
  font-size: medium;
  text-decoration: none;
}
.slider7 a:hover {
  color: var(--starmaroonhighlight) !important;
  font-weight: bold;
}
.slider7 button {
  margin: 1rem;
  display: block;
  background-color: var(--starblack);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}

/* Slider8 */
.slider8 {
  margin: 0 auto;
  background-color: var(--stargreen);
  justify-content: center;
  align-items: center;
  text-align: center;
}
.slider8 h3 {
  font-size: 2rem;
  text-align: center;
  color: var(--starwhite);
}

.slider8 h4 {
  font-size: 1.5rem;
  color: var(--starwhite);
}
.slider8 li {
  margin-top: -0.5rem;
  justify-content: center !important;
  align-items: center !important;
  padding-left: 5rem;
  text-decoration: none;
  color: #fff;
  text-align: left;
  line-height: 2rem;
  list-style-type: none;
}
.slider8 a {
  color: #fff;
  font-size:medium;
  text-decoration: none;
}
.slider8 a:hover {
  color: var(--starwhite) !important;
  font-weight: bold;
}
.slider8 button {
  margin: 1rem;
  display: block;
  background-color: var(--starblack);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}

/* Common Containers */
.container {
  margin: 0 auto !important;
  padding: 0rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
}
.container7 {
  margin: 0 auto !important;
  padding: 0rem 10rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
}
.container8 {
  margin: 0 auto !important;
  padding: 0rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
}

/* Product Card */
.productCard {
  width: 100%;
  text-align: center;
  font-size: small;
}

/* Signup Newsletter Form */
.signUp {
  background-color: var(--starmaroon);
}
.signUpSubcontainer {
  margin-top: 0.5rem;
  width: 50%;
  display: inline-flex;
  gap: 2rem;
}
.signUp h3 {
  margin: 0 auto;
  text-align: center;
  color: var(--starmaroonhighlight);
}
.signUp input {
  border: none;
  border-bottom: 2px solid white;
  background: none;
  color: #fff !important;
  padding: 0.8rem;
  font-size: 1rem;
  outline: none;
  width: 100%;
  transition: all 0.3s ease;
  text-align: center;
}
.signUp :focus {
  border-bottom: 2px solid rgb(233, 146, 161);
  box-shadow: 0 4px 2px -2px rgb(238, 146, 146);
  border-radius: 1rem;
  background-color: var(--starmaroonhighlight);
  color: var(--starwhite);
  transform: scale(1.1);
}
.signUp8 {
  background-color: var(--stargreen);
}
.signUpSubcontainer8 {
  margin-top: 0.5rem;
  width: 50%;
  display: inline-flex;
  gap: 2rem;
}
.signUp8 h3 {
  margin: 0 auto;
  text-align: center;
  color: var(--stargreenhighlight);
}
.signUp8 input {
  border: none;
  border-bottom: 2px solid white;
  background: none;
  color: #fff;
  padding: 0.8rem;
  font-size: 1rem;
  outline: none;
  width: 100%;
  transition: all 0.3s ease;
  text-align: center;
}
.signUp8 :focus {
  border-bottom: 2px solid rgb(223, 241, 188);
  box-shadow: 0 4px 2px -2px rgb(224, 247, 163);
  border-radius: 1rem;
  background-color: var(--stargreenhighlight);
  color: var(--starblack);
  transform: scale(1.1);
}
.signUp button {
  display: block;
  background-color: var(--starmaroonhighlight);
  color: #fff;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}
.signUp button:hover {
  color: #fff;
}

/* Footer */
.footer {
  margin: 2rem auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: var(--starwhite);
  font-size: small;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.footerHighlight img:hover {
  cursor: pointer;
}
.footerHighlight img {
  float: left;
  width: 80%;
}

/* Social */
.socials {
  padding: 1rem 5rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
}
.socials span {
  font-size: 1rem;
}
.socials span:hover {
  width: 15%;
  transform: scale(1.1);
}
/* Swing Frame */
@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

/* Form Submitted */
.submittedForm {
  text-align: center;
  margin-top: 2rem;
  color: var(--starwhite);
}

@media (max-width: 1120px) {
}
@media (max-width: 1120px) {
  .slider1 img {
    padding: 0rem;
    width: 100%;
  }
  .slider1 p {
    padding: 0rem;
  }
  .slider2 {
    padding: 2rem 0rem;
  }
  .slider2 img {
    width: 100%;
  }
  .slider2 p {
    padding: 0rem;
  }
}

@media (max-width: 768px) {
  /* Slider 1 */
  .slider1 img {
    width: 100%;
    padding: 2rem 0rem;
  }

  /* Slider2 */

  .slider2 p {
    text-align: left;
    padding: 0.5rem;
    color: var(--starblack);
  }

  .slider2 img {
    width: 100%;
  }

  /* Slider3 */
  .slider3 h1 {
    padding-top: 5rem;
  }

  /* Slider4 */
  .slider4 {
    padding: 2rem 1rem;
  }

  /* Slider6 */
  .slider6 h1 {
    padding-top: 1.5rem;
  }
  .slider6 {
    padding: 0.5rem !important;
  }

  /* Common Containers */
  .container {
    display: block;
    padding: 0rem 2rem;
  }
  .container7 {
    padding: 2rem 2rem;
    text-align: center;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .container8 {
    padding: 2rem 1rem;
    text-align: center;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .container7 h3 {
    text-align: center;
  }
  .container8 h3 {
    text-align: center;
  }

  /* Products Card */
  .productsCard {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .productCard h1 {
    margin-top: -2rem;
    padding-left: 0rem;
    font-size: 2rem;
    text-align: center;
  }

  /* Signup Newsletter */
  .signUp {
    padding: 1rem;
  }
  .signUpSubcontainer {
    width: 90%;
  }
  .signUpSubcontainer8 {
    width: 90%;
  }
  .footerHighlight img {
    float: initial;
    width: 50%;
  }
  .socials {
    padding: 1rem;
  }
}
